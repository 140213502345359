import i18nInit from './i18';

export interface IEnvironment {
  domain: string;
  supportedLanguages: string;
  fallbackLanguage: string;
  contextPath: string;
}

declare global {
  interface Window {
    isEnv: IEnvironment;
  }
}

/**
 * Will register configurable env variables and attach them to the window object in window.corpEnv
 * From here variables can be requested as window.corpEnv.variableName
 *
 * In production:
 *    Will fetch variables from json file from public/environment/environment.json
 *    The json should be complient with the ITiaWidgetOptions interface definition above
 *
 * In development:
 *    Will read from .env.development file and attach the associated variables
 *
 * NB: register() is asynchronous, and will have to be
 * awaited before mounting the root component to the DOM
 */
const register = async (): Promise<void> => {
  let options: IEnvironment;

  if (process.env.NODE_ENV === 'production') {
    const optionsJson = await fetch(`${process.env.PUBLIC_URL}/environment/environment.json`);
    options = await optionsJson.json();
  } else {
    options = {
      domain: process.env.REACT_APP_DOMAIN || '',
      supportedLanguages: process.env.REACT_APP_SUPPORTED_LANGUAGES || '',
      fallbackLanguage: process.env.REACT_APP_FALLBACK_LANGUAGE || '',
      contextPath: process.env.REACT_APP_CONTEXT_PATH || '',
    };
  }
  window.isEnv = options;
  i18nInit(options.supportedLanguages, options.fallbackLanguage, options.contextPath);
};

export default register;
