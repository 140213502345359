import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import arrowIcon from './arrowIcon';
import { IIdentity } from '../../identity/IIdentity';

interface IDropdown {
  placeholder: string;
  dropdownList: IIdentity[];
  handleButtonAtribute: Dispatch<SetStateAction<boolean>>;
  handleSelect: Dispatch<SetStateAction<string>>;
}

const Dropdown: FC<IDropdown> = props => {
  const [dropdownVisibility, setVisibility] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleDropdownOpen = () => {
    setVisibility(true);
    window.addEventListener('click', () => setVisibility(false), { once: true, capture: true });
  };

  const handleDropdownItemClick = (userId: string, label: string) => {
    setSelectedValue(label);
    props.handleButtonAtribute(false);
    props.handleSelect(userId);
  };

  const renderDropdownList = props.dropdownList.map(dropdown => {
    const label = `${dropdown.companyRegistrationNo} - ${dropdown.name}`;
    const key = dropdown.user_id;
    return(
      <DropdownItemButton key={key} onClick={() => handleDropdownItemClick(key, label)}>
        {label}
      </DropdownItemButton>
    )
  });

  return (
    <DropdownContainer>
      <Button onClick={handleDropdownOpen}>
        <span>{selectedValue || props.placeholder}</span>
        {arrowIcon}
      </Button>
      <DropdownOverlay theme={{ dropdownVisibility }}>{renderDropdownList}</DropdownOverlay>
    </DropdownContainer>
  );
};

export default Dropdown;

const DropdownContainer = styled.div`
  position: relative;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 283px;
  outline: 0;
  color: var(--tia-color-body-text, #405e6c);
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
`;

const DropdownOverlay = styled.div`
  display: flex;
  flex-direction: column;
  visibility: ${props => (props.theme.dropdownVisibility ? 'shown' : 'hidden')};
  position: absolute;
  overflow-y: scroll;
  max-height: 106px;
  width: 283px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  margin: 1px 0 0 0;
  padding: 12px 0 0 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DropdownItemButton = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  color: var(--tia-color-body-text, #405e6c);
  font-size: 14px;
  margin: 0 0 12px 12px;
  text-align: left;
  background-color: #ffffff;
`;
