import React from 'react';

const arrowIcon = (
  <svg width="12px" height="6px" viewBox="0 0 12 6" version="1.1">
    <defs>
      <path
        d="M12.2458984,4.97859339 C12.2458984,5.10984339 12.2021484,5.24246058 12.1146484,5.35320277 L7.45390625,9.39734339 C7.34042969,9.5381637 7.16953125,9.62019495 6.98769531,9.62019495 C6.80722656,9.62019495 6.63496094,9.5381637 6.52285156,9.39734339 L1.88671875,5.37781214 C1.68027344,5.12078089 1.72128906,4.74480433 1.97832031,4.53835902 C2.23535156,4.3319137 2.61132813,4.37292933 2.81777344,4.62996058 L6.9890625,8.06980433 L11.1835938,4.60398402 C11.3900391,4.34695277 11.7660156,4.30593714 12.0230469,4.51238245 C12.1693359,4.63132777 12.2458984,4.80359339 12.2458984,4.97859339 Z"
        id="path-1"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dropdown/Button/Secondary/Large" transform="translate(-64.000000, -18.000000)">
        <g id="Icon/Down" transform="translate(63.000000, 14.000000)">
          <mask id="mask-2" fill="white">
            <use href="#path-1"></use>
          </mask>
          <g id="Mask" fillRule="nonzero"></g>
          <g id="↳Color/Title" mask="url(#mask-2)" fill="#4E505E" fillRule="evenodd">
            <g transform="translate(-30.333333, -27.416667)">
              <circle id="Oval-Copy-6" cx="35" cy="35" r="35"></circle>
              <rect id="Rectangle" x="0" y="0" width="70" height="70"></rect>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default arrowIcon;
