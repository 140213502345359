import React, { FC } from 'react';
import styled from 'styled-components';

interface IButton {
  disabled: boolean;
  onClick: () => void;
}

const Button: FC<IButton> = (props) => {
  return (
    <ButtonStyle disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button`
  cursor: pointer;
  outline: 0;
  box-shadow: 0 1px 2px 0 rgba(62, 74, 85, 0.22), 0 5px 8px 0 rgba(112, 142, 142, 0.2);
  font-family: var(--tia-font-family, 'Muli');
  font-weight: 800;
  font-size: 16px;
  color: #ffffff;
  background-color: var(--tia-color-primary, #da291c);
  text-align: center;
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 4px;
  width: 100%;
  border: none;
  &:disabled {
    cursor: disabled;
    background-color: var(--tia-color-primary-active, #ff907d);
  }
`;

export default Button;
