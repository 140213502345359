import React, { Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from './components/Dropdown/Dropdown';
import Button from './components/Button/Button';
import { getListOfIdentities } from './identity/identityhelpers';
import { IIdentity } from './identity/IIdentity';
import { useTranslation } from 'react-i18next';

const AppClass: React.FC = () => {
  const [buttonDisabledAttribute, setButtonDisabledAttribute] = useState<boolean>(true);
  const [selectedFirm, setSelectedFirm] = useState<string>('');
  const [corporateFirmList, setCorporateFirmList] = useState<IIdentity[]>([]);
  const { t } = useTranslation();

  const handleClick = (): void => {
    const params = new URLSearchParams(window.location.search);
    params.append('corporatefirm', selectedFirm);
    const path = `https://${window.isEnv.domain}/continue?${params.toString()}`;
    window.location.replace(path);
  };

  useEffect(() => {
    try {
      const list = getListOfIdentities();
      setCorporateFirmList(list);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <AppWindow>
      <AppContainer>
        <CompanyLogo />
        <DropdownLabel>{t('views.app.selectOrganizationCallingText')}</DropdownLabel>
        <Dropdown
          handleButtonAtribute={setButtonDisabledAttribute}
          handleSelect={setSelectedFirm}
          dropdownList={corporateFirmList}
          placeholder={t('views.app.selectOrganizationInputPlaceholder')}
        />
        <Margin theme={{ margin: '18px 0 0 0' }}>
          <Button disabled={buttonDisabledAttribute} onClick={handleClick}>
            {t('views.app.buttonContinue')}
          </Button>
        </Margin>
      </AppContainer>
    </AppWindow>
  );
};

const AppWindow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--tia-body-bg, #e2e9f8);
  font-family: var(--tia-font-family, 'Muli');
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 36px 36px 36px;
  width: 355px;
  background: var(--tia-color-cmp-bg, #ffffff);
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.1);
`;

const DropdownLabel = styled.p`
  padding: 36px 0 12px 0;
  color: var(--tia-color-body-text, #405e6c);
  font-size: 14px;
`;

const CompanyLogo = styled.div`
  background: var(
    --tia-logo-url,
    url('https://portal.theinsuranceapplication.com/assets/images/Lake_Logo_Tia_logo_Pos.svg')
  );
  width: 100%;
  height: 80px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Margin = styled.div`
  width: 100%;
  margin: ${(props): string => props.theme.margin};
`;

export default function App(): JSX.Element {
  return (
    <Suspense fallback="loading">
      <AppClass />
    </Suspense>
  );
}
