import { decode } from 'js-base64';
import { IIdentity } from './IIdentity';

/**
 * IMPORTANT: depending on how auth0 lets us add custom query params we can either keep this,
 * or we need to decode the "state" parameter and fetch the stringifyet list of identities from there.
 */
export const getListOfIdentities = (): IIdentity[] => {
  const encodedList = new URLSearchParams(window.location.search).get('corporatefirms');
  if (encodedList) {
    return JSON.parse(decode(encodedList));
  }
  throw Error('no identities located');
};
